import React from "react";
import Header from "../molecules/Header";

const Marketplace = () => {
  return (
    <div>
      <Header active="marketplace" />
      <iframe
        className="w-full h-[85vh] mt-2"
        title="Passion Spirits"
        src="https://www.passionspirits.com/"
      ></iframe>
    </div>
  );
};

export default Marketplace;
