import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './components/layouts/Home';
import Categories from './components/layouts/Categories';
import App from './App';
import Marketplace from './components/layouts/Marketplace';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import WhiteLabels from './components/layouts/WhiteLabels';
import ShopifyUx from './components/layouts/ShopifyUx';
import Campaings from './components/layouts/Campaings';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "categories",
    element: <Categories />,
  },
  {
    path: "marketplace",
    element: <Marketplace />,
  }, {
    path: "white-labels",
    element: <WhiteLabels />,
  },
  {
    path: "shopify-ux",
    element: <ShopifyUx />,
  },
  {
    path: "campaings",
    element: <Campaings />,
  },
  {
    path: "*",
    element: <App />,
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

