import React, { useState } from "react";
import { Link } from "react-router-dom";
import Lightbox from "react-spring-lightbox";

const activeStyle = "shadow-2xl bg-gray-100";
const images = [
  {
    src: "/png/qr-contact.png",
    loading: "lazy",
    alt: "contact",
  },
];
const Header = ({ active }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <nav className="flex gap-4 shadow-md">
      <Link to="/">
        <img src="/png/home-banner.png" className="max-h-[400px]" alt="HOME" />
      </Link>
      <ul className="flex gap-6 my-1 items-center">
        <li>
          <div
            className={`p-2 p-2 max-h-24 hover:shadow-2xl hover:shadow-2xl hover:bg-gray-100 ${
              active === "marketplace" ? activeStyle : ""
            }`}
          >
            <Link to="/marketplace">
              <img src="/png/marketplace.png" alt="MARKETPLACE" />
            </Link>
          </div>
        </li>
        <li>
          <div
            className={`p-2 max-h-24 hover:shadow-2xl hover:bg-gray-100 ${
              active === "white-labels" ? activeStyle : ""
            }`}
          >
            <a href="/white-labels">
              <img src="/png/white-label.png" alt="WHITE-LABEL" />
            </a>
          </div>
        </li>
        <li>
          <div
            className={`p-2 max-h-24 hover:shadow-2xl hover:bg-gray-100 ${
              active === "shopify-ux" ? activeStyle : ""
            }`}
          >
            <a href="/shopify-ux">
              <img src="/png/shopify-ux.png" alt="SHOPIFY - UX" />
            </a>
          </div>
        </li>
        <li>
          <div
            className={`p-2 max-h-24 hover:shadow-2xl hover:bg-gray-100 ${
              active === "e-campaings" ? activeStyle : ""
            }`}
          >
            <Link to="/campaings">
              <img src="/png/e-campaings.png" alt="E-CAMPAINGS" />
            </Link>
          </div>
        </li>
        <li>
          <div className="mt-1 mr-8">
            <button onClick={handleOpen}>
              <img
                className="w-[400px]"
                src="/png/qr-contact.png"
                alt="LET'S TALK"
              />
            </button>
          </div>
        </li>
      </ul>

      <Lightbox
        isOpen={open}
        images={images}
        currentIndex={0}
        className="backdrop-blur-md "
        onClose={handleClose}
      />
    </nav>
  );
};

export default Header;
