import React from "react";
import { Link } from "react-router-dom";

const Categories = () => {
  return (
    <div className="flex h-screen w-full">
      <div className="w-1/2 flex flex-col justify-between py-20 px-32">
        <Link to="/marketplace">
          <img src="/png/marketplace.png" alt="MARKETPLACE" />
        </Link>
        <Link to="/white-labels">
          <img src="/png/white-label.png" alt="WHITE-LABEL" />
        </Link>
        <Link to="/shopify-ux">
          <img src="/png/shopify-ux.png" alt="SHOPIFY - UX" />
        </Link>
        <Link to="/campaings">
          <img src="/png/e-campaings.png" alt="E-CAMPAINGS" />
        </Link>
      </div>
      <div className="flex flex-col leading-tight justify-center gap-14 bg-black w-1/2 text-white text-5xl p-28 text-center">
        <p>
          As a pioneer in the DTC channel, we provide all available solutions in
          the space.
        </p>
        <p>Check them out!</p>
      </div>
    </div>
  );
};

export default Categories;
