import React, { useState } from "react";
import { Link } from "react-router-dom";
const Home = () => {
  const [isFullscreen, setIsFullscreen] = useState(
    !!document.fullscreenElement
  );

  const openFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    }
  };

  return (
    <div className="h-screen w-full flex justify-center items-center bg-black ">
      <div className="flex flex-col max-w-7xl items-center text-white justify-end gap-28 pb-16">
        <div className="flex flex-col items-center">
          <h1 className="text-[172px] uppercase font-serif text-center tracking-wide">
            Welcome
          </h1>
          <img
            className="w-[600px]"
            src="/png/logo-white.png"
            alt="Passion Spirits"
          />
        </div>
        <Link to="categories">
          <button className="uppercase font-serif text-[82px] border-2 rounded-xl border-white px-6">
            Start here
          </button>
        </Link>
      </div>
      {!isFullscreen && (
        <button
          onClick={openFullScreen}
          className="text-white text-5xl fill-white absolute bottom-10 right-10"
        >
          <img src="/fullscreen.svg" alt="FULLSCREEN" />
        </button>
      )}
    </div>
  );
};

export default Home;
