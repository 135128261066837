import React from "react";

const MenuWhiteLabels = () => {
  return (
    <div className="font-serif text-center mt-8 flex flex-col items-center">
      <p className="font-bold uppercase text-3xl">
        &gt;100K POTENTIAL CUSTOMERS AT YOUR FINGERTIPS
      </p>
      <p className="uppercase text-3xl font-light mt-8 w-4/5">
        OUR DIGITAL CAMPAIGNS ARE CREATED BY YOU OR BY OUR DESIGN TEAM. THIS IS
        A STRONG TACTICAL TOOL TO GENERATE SALES.
      </p>
      <p className="font-light text-5xl tracking-[.4em] uppercase mt-16 mb-14">
        Choose an experience
      </p>
    </div>
  );
};

export default MenuWhiteLabels;
