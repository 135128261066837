import React, { useState } from "react";
import Header from "../molecules/Header";
import MenuCampaings from "../molecules/MenuCampaings";
import Lightbox from "react-spring-lightbox";

const images = [
  {
    src: "/e-campaings/1.png",
    loading: "lazy",
    alt: "e-campaing-1",
  },
  {
    src: "/e-campaings/2.png",
    loading: "lazy",
    alt: "e-campaing-2",
  },
  {
    src: "/e-campaings/3.png",
    loading: "lazy",
    alt: "e-campaing-3",
  },
  {
    src: "/e-campaings/4.png",
    loading: "lazy",
    alt: "e-campaing-4",
  },
  {
    src: "/e-campaings/5.png",
    loading: "lazy",
    alt: "e-campaing-5",
  },
  {
    src: "/e-campaings/6.png",
    loading: "lazy",
    alt: "e-campaing-6",
  },
  {
    src: "/e-campaings/7.png",
    loading: "lazy",
    alt: "e-campaing-7",
  },
  {
    src: "/e-campaings/8.png",
    loading: "lazy",
    alt: "e-campaing-8",
  },
  {
    src: "/e-campaings/9.png",
    loading: "lazy",
    alt: "e-campaing-9",
  },
  {
    src: "/e-campaings/10.png",
    loading: "lazy",
    alt: "e-campaing-10",
  },
  {
    src: "/e-campaings/11.png",
    loading: "lazy",
    alt: "e-campaing-11",
  },
  {
    src: "/e-campaings/12.png",
    loading: "lazy",
    alt: "e-campaing-12",
  },
  {
    src: "/e-campaings/13.png",
    loading: "lazy",
    alt: "e-campaing-13",
  },
  {
    src: "/e-campaings/14.png",
    loading: "lazy",
    alt: "e-campaing-14",
  },
  {
    src: "/e-campaings/15.png",
    loading: "lazy",
    alt: "e-campaing-15",
  },
  {
    src: "/e-campaings/16.png",
    loading: "lazy",
    alt: "e-campaing-16",
  },
  {
    src: "/e-campaings/17.png",
    loading: "lazy",
    alt: "e-campaing-17",
  },
  {
    src: "/e-campaings/18.png",
    loading: "lazy",
    alt: "e-campaing-18",
  },
  {
    src: "/e-campaings/19.png",
    loading: "lazy",
    alt: "e-campaing-19",
  },
  {
    src: "/e-campaings/20.png",
    loading: "lazy",
    alt: "e-campaing-20",
  },
];

const Campaings = () => {
  const [currentImageIndex, setCurrentIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = (index) => {
    setOpen(true);
    setCurrentIndex(index);
  };

  const gotoPrevious = () =>
    currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () => {
    currentImageIndex + 1 < images.length &&
      setCurrentIndex(currentImageIndex + 1);
  };

  const renderCloseButton = () => (
    <button className="absolute right-[-40px] " onClick={handleClose}>
      <img src="/close.svg" className="w-8 text-white fill-white" alt="X" />
    </button>
  );
  return (
    <div>
      <Header active="e-campaings" />
      <MenuCampaings />
      <div className="grid grid-cols-4 gap-6 justify-items-center mx-8 mb-16">
        {images.map((image, index) => (
          <img
            onClick={() => handleOpen(index)}
            key={image.alt}
            src={image.src}
            alt={image.alt}
          />
        ))}
      </div>
      <Lightbox
        isOpen={open}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        images={images}
        renderImageOverlay={renderCloseButton}
        currentIndex={currentImageIndex}
        className="backdrop-blur-md "
        onClose={handleClose}
      />
    </div>
  );
};

export default Campaings;
