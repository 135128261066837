import React from "react";

const MenuShopifyUx = () => {
  return (
    <div className="font-serif text-center mt-8 flex flex-col items-center">
      <p className="font-bold uppercase text-3xl">
        The pioneer of the SHOPIFY-UX SOLUTION
      </p>
      <p className="uppercase text-3xl font-light ">
        YOU BUILD AND MANAGE THE FRONTEND OF YOUR ONLINE STORE IN SHOPIFY, AND
        PASSION SPIRITS CONTROLS THE CHECKOUT AND FULFILMENT.
      </p>
      <p className="font-light text-5xl tracking-[.4em] uppercase my-16">
        Choose an experience
      </p>
      <div className="flex justify-evenly w-full items-center mb-12">
        <a href="https://us.jamesgin.com/" target="_blank" rel="noreferrer">
          <img src="/png/james-gin.png" className="w-52" alt="James Gin" />
        </a>

        <a
          href="https://shop.paquitequila.com/collections/the-collection"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/png/paqui.png" className="w-52" alt="Paqui Tequila" />
        </a>
        <a href="https://ginlane1751.com/" target="_blank" rel="noreferrer">
          <img src="/png/gin-lane.png" className="w-72" alt="Gin Lane" />
        </a>
      </div>
      <p className="uppercase text-xl font-light mx-52 mt-4 text-neutral-400">
        UPON SELECTING ANY OF THE ABOVE PRODUCTS YOU WILL BE REDIRECTED TO A NEW
        TAB TO VISUALIZE THE BRAND’S WEBSITE. SELECT THE CURRENT WINDOW TAB TO
        COME BACK TO THIS SCREEN.
      </p>
    </div>
  );
};

export default MenuShopifyUx;
