import React, { useState } from "react";
import Header from "../molecules/Header";
import MenuWhiteLabels from "../molecules/MenuWhiteLabels";

const urls = {
  santaTeresa: "https://www.buysantateresarum.com/santa-teresa-rum.html",
  equiano: "https://equianorum.passionspirits.com/equiano-rum-750ml.html",
  dewars: "https://customlabels.dewars.com/dewars.html#step1",
  totalWine:
    "https://entremanos.passionspirits.com/entremanos-tequila-blanco.html",
};

const WhiteLabels = () => {
  const [experience, setExperience] = useState();

  const handleSetExperience = (experience) => {
    setExperience(experience);
  };

  return (
    <div>
      <Header active="white-labels" />
      {!experience && (
        <MenuWhiteLabels onSelectExperience={handleSetExperience} />
      )}
      {!!experience && (
        <iframe
          className="w-full h-[85vh] mt-2"
          title="Passion Spirits"
          src={urls[experience]}
        ></iframe>
      )}
    </div>
  );
};

export default WhiteLabels;
