import React from "react";
import Header from "../molecules/Header";
import MenuShopifyUx from "../molecules/MenuShopifyUx";

const ShopifyUx = () => {
  return (
    <div>
      <Header active="shopify-ux" />
      <MenuShopifyUx />
    </div>
  );
};

export default ShopifyUx;
