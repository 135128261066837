import React from "react";

const MenuWhiteLabels = ({ onSelectExperience }) => {
  return (
    <div className="font-serif text-center mt-8 flex flex-col items-center">
      <p className="font-bold uppercase text-3xl">
        The pioneer of the White-LABEL SOLUTION
      </p>
      <p className="uppercase text-3xl font-light w-1/2">
        WE power CUSTOM e-commerce platforms FOR Our clients
      </p>
      <p className="font-light text-5xl tracking-[.4em] uppercase mt-16 mb-14">
        Choose an experience
      </p>
      <div className="grid grid-cols-2 gap-6 justify-items-center">
        <button
          onClick={() => {
            onSelectExperience("equiano");
          }}
        >
          <img src="/png/equiano.png" className="w-72" alt="Equiano Rum" />
        </button>
        <a
          href="https://entremanos.passionspirits.com/entremanos-tequila-blanco.html"
          target="_blank"
          rel="noreferrer"
          className="flex items-center justify-center"
        >
          <img src="/png/entremanos.webp" className="w-72" alt="Entremanos" />
        </a>
        <a
          href="https://www.buysantateresarum.com/santa-teresa-rum.html"
          target="_blank"
          rel="noreferrer"
          className="flex items-center justify-center"
        >
          <img src="/png/santa-teresa.png" className="w-72" alt="SantaTeresa" />
        </a>
        <a
          href="https://customlabels.dewars.com/dewars.html"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/png/dewars.png" className="w-96" alt="Dewars" />
        </a>
      </div>
      <p className="uppercase text-xl font-light mx-52 mt-4 text-neutral-400">
        UPON SELECTING "SANTA TERESA<small>®</small> RUM 1796", "DEWAR’S
        <small>®</small> 1846" OR "ENTREMANOS" YOU WILL BE REDIRECTED TO A NEW
        TAB TO VISUALIZE THE BRAND’S WEBSITE. SELECT THE CURRENT WINDOW TAB TO
        COME BACK TO THIS SCREEN.
      </p>
    </div>
  );
};

export default MenuWhiteLabels;
